<template>
  <div class="pa-2">
    <v-card flat class="pa-1">
      <v-data-table
        :headers="headers"
        :items="desserts"
        sort-by="calories"
        class="elevation-0"
        :search="search"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <template v-slot:top>
          <v-row class="ma-0">
            <v-col cols="12" md="5">
              <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <div style="text-align: right">
                <v-btn medium color="primary" class="ml-1" @click="New()">
                  <v-icon left>mdi-plus</v-icon>
                  NEW
                </v-btn>

                <v-btn medium color="error" @click="btn_reload" class="ml-1">
                  <v-icon left>mdi-reload</v-icon>LOAD</v-btn
                >

                <v-btn
                  v-if="$store.state.deadline_set == 'Y'"
                  medium
                  color="primary"
                  @click="btn_bulkupdate"
                  class="ml-1"
                >
                  <v-icon left>mdi-calendar-clock</v-icon>DEADLINE</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.actions="{ item, on, attrs }">
          <v-btn
            medium
            outlined
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="ma-1"
            @click="editItem(item)"
            icon
          >
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>
    <v-overlay :value="sheet"></v-overlay>
    <v-navigation-drawer
      v-model="sheet"
      right
      persistent
      fixed
      class="ma-0"
      style="width:100%; max-width: 500px;"
    >
          <v-card
            flat color="white" width="100%" class="ma-0">
            <v-toolbar dense color="primary" flat dark>
              <v-icon>mdi-edit</v-icon>{{ EntryMode }}
              <v-spacer></v-spacer>

              <v-btn icon light>
                <v-icon color="red" @click="sheet = !sheet"
                  >mdi-close</v-icon
                >
              </v-btn>
            </v-toolbar>
            <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="postBody.company_name"
                      label="Company Name"
                      outlined
                      dense
                      :disabled="enableFlag"
                      :rules="Vcompany_name"
                      hide-details
                      :maxlength="setmaxlength"
                    ></v-text-field>
                  </v-col>

                    <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="postBody.user_id"
                      label="User Name"
                      outlined
                      dense
                      :rules="VUserName"
                    
                      max-lenght="15"
                      :disabled="enableFlag"
                      maxlength=15
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-autocomplete
                      v-model="Selcompanytype"
                      outlined
                      :items="companytype_id"
                      item-text="companytype_name"
                      item-value="companytype_id"
                      label="Select Company Type"
                      hide-details
                      attach
                      chips
                      small-chips
                      deletable-chips
                      multiple
                      menu-props="auto"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" v-if="emailcheckbox == true">
                    <v-checkbox
                      v-model="emailenableFlag"
                      label="Edit Email ID"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="postBody.vendor_email"
                      label="Email Id"
                      outlined
                      dense
                      :rules="VEmailid"
                      hide-details
                      :disabled="!emailenableFlag"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="postBody.no_of_application"
                      label="Application Limit"
                      outlined
                      dense
                      :rules="Vnumber"
                     
                      maxlength="4"
                      hide-details
                      @keypress="filterNum(event)"  
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="12">
                    <v-card outlined>
                      <v-subheader>Set Deadline</v-subheader>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="12">
                            <v-dialog
                              ref="dialog1"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  v-model="date"
                                  label="Deadline (Date)"
                                  prepend-icon="mdi-calendar"
                                  dense
                                  readonly
                                  hide-details
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="Vcompany_name"
                                  :disabled="!deadline"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :min="new Date().toISOString().slice(0, 10)"
                                v-model="date"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog1.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-dialog
                              ref="dialog"
                              v-model="modal2"
                              :return-value.sync="time"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  v-model="time"
                                  label="Deadline (Time)"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  dense
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="Vcompany_name"
                                  :disabled="!deadline"
                                  hide-details
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="modal2"
                                v-model="time"
                                full-width
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal2 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(time)"
                                >
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="12" v-if="emailcheckbox == true">
                    <v-checkbox
                      v-model="adminListEnableFlag"
                      label="Edit Approver"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-autocomplete
                      v-model="selSubAdminList"
                      outlined
                      :items="subAdminList"
                      item-text="name"
                      item-value="eaid"
                      label="Select Approver *"
                      hide-details
                      attach
                      chips
                      small-chips
                      deletable-chips
                      multiple
                      menu-props="auto"
                      :disabled="!adminListEnableFlag"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="12" hidden>
                    <v-text-field
                      v-model="postBody.vendor_name"
                      label="Vendor Name"
                      outlined
                      required
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions class="justify-center">
                <v-btn
                  color="success"
                  :disabled="!valid"
                  raised
                  dark
                  large
                  @click="save"
                  class="pl-4 pr-4"
                >
                <v-icon left>mdi-content-save</v-icon>
                  SAVE
                </v-btn>
              </v-card-actions>

            </v-form>
            </v-card-text>
          </v-card>
    </v-navigation-drawer>

    <v-overlay :value="sheetbulk"></v-overlay>
    <v-navigation-drawer
      v-model="sheetbulk"      
      right
      persistent
      fixed
      style="width: 100%; max-width: 400px; margin: auto;"
    >
      
          <v-card flat style="width: 100%; margin: auto;">
            <v-toolbar color="primary" flat dark>
              <v-icon>mdi-edit</v-icon>{{ EntryMode }}
              <v-spacer></v-spacer>

              <v-btn icon light>
                <v-icon color="red" @click="sheetbulk = !sheetbulk"
                  >mdi-close</v-icon
                >
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-form ref="formbulk" v-model="validbulk">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-card flat>
                      <v-subheader>Set Deadline</v-subheader>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="12">
                            <v-dialog
                              ref="dialogdatebulk"
                              v-model="modaldatebulk"
                              :return-value.sync="datebulk"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  v-model="datebulk"
                                  label="Date"
                                  prepend-icon="mdi-calendar"
                                  :rules="Vdatebulk"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="datebulk" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modaldatebulk = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialogdatebulk.save(datebulk)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-dialog
                              ref="dialogtimebulk"
                              v-model="modaltimebulk"
                              :return-value.sync="timebulk"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  outlined
                                  v-model="timebulk"
                                  label="Time"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  :rules="Vtimebulk"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="modaltimebulk"
                                v-model="timebulk"
                                full-width
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modaltimebulk = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialogtimebulk.save(timebulk)"
                                >
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions class="justify-center">
                <v-btn
                  color="success"
                  :disabled="!validbulk"
                  raised
                  dark
                  large
                  @click="savebulk"
                >
                  SAVE
                </v-btn>
              </v-card-actions>

              <!-- <v-footer class="mt-6 justify-center">
          
        </v-footer> -->
            </v-form>
          </v-card>
        
    </v-navigation-drawer>

    <v-snackbar v-model="snackbar" timeout="1000" top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay" style="z-index: 9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      emailcheckbox: false,
      emailenableFlag: false,
      adminListEnableFlag:false,
      deadline: true,
      enableFlag: false,
      setmaxlength: "25",
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: false,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",
      //companytype: [],
      companytype_id: [],
      subAdminList:[],
      selSubAdminList:[],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        user_id:"",

        no_of_application: "",
        deadline: "",
        companytype_id: [],
        vendor_id: "",
        sendMail: false,
        eventadmin_id:[]
        // Vendor_ID_Update: "",
        // uflag:"0",
        // FlagCompanyName:"",
        // FlagEmail:"",
      },

      postBodyNEW: {
        company_name: "TESTING1234",
        event_id: 5,
        venue_id: 2,
        vendor_name: "Chandran R",
        vendor_email: "chandran@dnanetworks.com",
        no_of_application: "468",
        deadline: "2021-05-31T23:59",
        companytype_id: [12, 13],
      },

      postBodyBulk: {
        event_id: "",
        venue_id: "",
        deadline: "",
      },
      headers: [
        {
          text: "SR",
          align: "left",
          value: "s_no",
          class: "blue lighten-5",
        },

        // {
        //   text: "ID",
        //   align: "left",
        //   sortable: false,
        //   value: "vendor_id",
        //   class: "blue lighten-5",
        // },
        {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "UserId",
          value: "User_id",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Email",
          value: "coordinator_email",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Limit",
          value: "no_of_application",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Deadline",
          value: "deadline",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Created on",
          value: "created_date",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
          class: "blue lighten-5",
        },
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"

      ],
       Vnumber: [
        (v) => !!v || "Required",
(v) =>!isNaN(parseFloat(v)) && v >= 1 && v <= 9999 || "Number Only",
      ],

     // /^[0-9a-zA-Z]+$/

       

      VEmailid: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

        VUserName: [
        (v) => !!v || "Required",
        (v) => /^[0-9a-zA-Z]+$/.test(v) || "User name should be only alphanumeric",
      ],

      inputRule: [(v) => v.email.length >= 3 || "Please select a user"],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
   // this.setmaxlength = this.$store.state.eventInfo.CompanyNameSize;
   
   //console.log("check lenght text " + this.$store.state.eventInfo.CompanyNameSize);

   if(this.$store.state.eventInfo.CompanyNameSize!="0")
   {
    this.setmaxlength = this.$store.state.eventInfo.CompanyNameSize;
   }
   else
   {
    this.setmaxlength="25"
   }
    
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    sessionStorage.EVENTID = "1";
    sessionStorage.VENUEID = "1";

    this.$store.commit("setPageTitle", "Company Master");
    this.$store.commit("setPageTitleIcon", "mdi-bank");

    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    this.Reload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    this.bindcompanytype();
    if (this.$store.state.deadline_set == "Y") {
      this.deadline = true;

    } else {
      this.deadline = false;
     // alert(this.$store.state.eventInfo.Deadline);
      //console.log(this.$store.state.Deadline);

 this.date = moment(this.$store.state.eventInfo.Deadline, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          );
          this.time = moment(this.$store.state.eventInfo.Deadline).format("HH:mm");


     // this.date="2022-03-15";
     // this.time="23:59"
    }
    this.bindSubAdmin();
  },
  methods: {

filterNum: function(evt) {
      evt = (evt) ? evt : this.window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    bindcompanytype: async function () {
      
      //alert(this.$store.state.eventInfo.EventId + "/" + this.$store.state.eventInfo.VenueId);
      await this.$axios
        .get(
         "Companytype/GetByEventIdVenueId/" +
            this.$store.state.eventInfo.EventId + "/" + this.$store.state.eventInfo.VenueId)
        .then((res) => {
          console.log("Check company");
          console.log(res.data.result);
          this.companytype_id = res.data.result;
        })
        .catch()
        .finally();
    },

    bindSubAdmin: async function () {
      await this.$axios
        .get(
         "EventAdmin/subAdmin/" +
            this.$store.state.eventInfo.EventId)
        .then((res) => {
          this.subAdminList = res.data.result;
        })
        .catch()
        .finally();
    },

    btn_reload: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );
    },

    btn_bulkupdate: function () {
      this.sheetbulk = true;
      this.datebulk = "";
      this.timebulk = "";
      this.EntryMode = "SET GROUP DEADLINE";
    },

    Reload: async function (event_id, venue_id) {
      this.loadingstatus = true;
     await  this.$axios
        .get(
           "EventDashboard/companyList/" +
            event_id +
            "/" +
            venue_id
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            this.desserts = res.data.result;
            console.table(this.desserts);
          }
          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
    New() {
      this.EntryMode = "New Vendor";
      //  this.postBody.event_name = "";
      this.enableFlag = false;
      this.CleareData();
      this.sheet = true;
      this.emailenableFlag = true;
      this.emailcheckbox = false;
      this.adminListEnableFlag=true;
    },
    CleareData() {
      this.postBody.company_name = "";
      this.postBody.vendor_name = "";
      this.postBody.user_id = "";
      this.Selcompanytype = "";
      //  this.companytype_id=[];
      this.postBody.companytype_id = [];
      this.postBody.vendor_email = "";
      this.postBody.no_of_application = "";
      this.selSubAdminList =[];
      this.postBody.eventadmin_id=[];
      // this.$refs.form.reset();

    // this.date = "";
     // this.time = "";

if (this.$store.state.deadline_set == "Y") {
  this.date = "";
      this.time = "";
    } else {
     // this.date="2022-03-15";
     // this.time="23:59"
    // alert(this.$store.state.eventInfo.Deadline);
    // alert(moment(this.$store.state.eventInfo.Deadline,"YYYY-MM-DD").format("YYYY-MM-DD"));
     
      this.date = moment(this.$store.state.eventInfo.Deadline, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          );
          this.time = moment(this.$store.state.eventInfo.Deadline).format("HH:mm");
    }

    },
    editItem(item) {
      this.cardID = item.vendor_id;
      this.GetVendorData(item.vendor_id);
      this.Vendor_ID_Update = item.vendor_id;
      this.sheet = true;
      this.EntryMode = "Edit Vendor";
      if(this.$store.state.eventInfo.UType=="DnaAdmin")
      {
        this.enableFlag = false;
      }
      else{
        this.enableFlag = true;
      }
      
      this.emailenableFlag = false;
      this.emailcheckbox = true;
    },

    close() {
      this.sheet = false;
      //  this.Reload();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    GetVendorData: async function (Vendor_ID) {
      // this.clearefields();
      this.overlay = true;
      await this.$axios
        .get("Vendor/" + Vendor_ID)
        .then((res) => {
          // this.getdata = res.data.result;

          this.postBody.company_name = res.data.result.company_name;
          // this.FlagCompanyName=res.data.result[0].company_name;
          this.postBody.vendor_name = res.data.result.vendor_name;
          this.postBody.vendor_email = res.data.result.vendor_email;
          this.FlagEmail = res.data.result.vendor_email;
          this.postBody.user_id = res.data.result.user_id;
          this.postBody.no_of_application = res.data.result.no_of_application;
          this.date = moment(res.data.result.deadline, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          );
          this.time = moment(res.data.result.deadline).format("HH:mm");
          this.Selcompanytype = res.data.result.companytype_id;
          this.selSubAdminList = res.data.result.eventadmin_id;
          this.overlay = false;
        })
        .catch()
        .finally();
    },

    GetVendorData1: async function (Vendor_ID) {
      this.loadingstatus = true;
      await this.$axios
        .get("Vendor/" + Vendor_ID)
        .then((res) => {
         
          this.postBody.company_name = res.data.result.company_name;

          this.loadingstatus = false;
       
        })
        .catch()
        .finally();
    },

    savebulk() {
      this.postBodyBulk.event_id = this.$store.state.eventInfo.EventId;
      this.postBodyBulk.venue_id = this.$store.state.eventInfo.VenueId;
      this.postBodyBulk.deadline = this.datebulk + "T" + this.timebulk;
      this.$refs.formbulk.validate();
      // this.desserts.push(this.editedItem);
      this.$axios
        .put("Vendor/UpdateAllDeadline", this.postBodyBulk)
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("Success", "Deadline Updated!");
            this.Reload(
              this.$store.state.eventInfo.EventId,
              this.$store.state.eventInfo.VenueId
            );
            this.sheetbulk = false;
          }
        })
        .catch()
        .finally();
      this.close();
    },

    save() {
      this.overlay = true;

      this.postBody.event_id = this.$store.state.eventInfo.EventId;
      this.postBody.venue_id = this.$store.state.eventInfo.VenueId;
      this.postBody.companytype_id = this.Selcompanytype;
      this.postBody.eventadmin_id = this.selSubAdminList;
      this.postBody.deadline = this.date + "T" + this.time;
      if (this.Selcompanytype.length <= 0) {
        this.overlay = false;

        this.showSnackbar("error", "Select Company type!");
        this.sheet = true;
        return;
      }

      if (this.selSubAdminList.length <= 0) {
        this.overlay = false;

        this.showSnackbar("error", "Select Approver!");
        this.sheet = true;
        return;
      }

      this.$refs.form.validate();

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
        if (this.EntryMode == "New Vendor") {
          this.$axios
            .post("Vendor", this.postBody)
            .then((res) => {
              if (res.data.status == "Success") {
                if (res.data.result.VID == "ALREADY EXIST") {
                  this.showSnackbar("Success", "Vendor Already Exist!");
                  this.overlay = false;
                  this.sheet = true;
                  return;
                } else {
                  this.showSnackbar("Success", "Vendor Created!");
                  this.overlay = false;
                  this.CleareData();
                  this.Reload(
                    this.$store.state.eventInfo.EventId,
                    this.$store.state.eventInfo.VenueId
                  );
                }
              }
            })
            .catch()
            .finally();
        } else if (this.EntryMode == "Edit Vendor") {
          
          // if(this.postBody.company_name==this.FlagCompanyName && this.postBody.vendor_email==this.FlagEmail)
          // {
          //    this.uflag="0";
          // }
          //  else if(this.postBody.company_name !=this.FlagCompanyName && this.postBody.vendor_email!=this.FlagEmail){
          //   this.uflag="1";
          //  }
          //  else if(this.postBody.company_name ==this.FlagCompanyName && this.postBody.vendor_email!=this.FlagEmail){
          //   this.uflag="2";
          //  }
          //  else if(this.postBody.company_name !=this.FlagCompanyName && this.postBody.vendor_email==this.FlagEmail){
          //    this.uflag="3";
          //  }

          this.postBody.companytype_id = this.Selcompanytype;
          this.postBody.vendor_id = this.Vendor_ID_Update;
          this.postBody.sendMail = this.emailenableFlag;
          // this.postBody.uflag=this.uflag;
          //console.log(this.postBody);
          this.$axios
            .put("Vendor/", this.postBody)
            .then((res) => {
              console.log(res.data);
              if (res.data.status == "Success") {
                if (res.data.message == "ALREADYEXISTS") {
                  if(this.postBody.sendMail==true)
                  {
                    this.showSnackbar("Success", "Vendor email already Exits!");
                  }
                  else{
                    this.showSnackbar("Success", "Company Name already Exits!");
                  }
                  
                  this.overlay = false;
                } else {
                  this.showSnackbar("Success", "Vendor Updated!");
                  this.overlay = false;
                }
                //  }

                this.overlay = false;
                this.Reload(
                  this.$store.state.eventInfo.EventId,
                  this.$store.state.eventInfo.VenueId
                );
              }
            })
            .catch()
            .finally();
        }
      }
      this.close();
    },
  },
};
</script>

<style>
</style>